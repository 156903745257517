import React, {Component} from "react";
import {Button, Form, Input, message} from "antd";
import {LockOutlined, MailOutlined, PictureOutlined, UserOutlined} from "@ant-design/icons";
import {motion} from "framer-motion";
import '../UserForm.less'
import {apiRegister} from "../../../API/API";

class RegisterForm extends Component {
    state = {
        username: '',
        email: '',
        password: '',
        avatar: '',
        submit: false,
    }
    handleSubmitRegister = values => {
        console.log('Received values of form: ', values);
        this.setState({
            username: values.username,
            email: values.email,
            password: values.password,
            avatar: values.avatar,
            submit: true
        })
    };

    handlePhotographer = () => {
        this.submitRegister('photographer')
    }

    handleModel = () => {
        this.submitRegister('model')
    }

    submitRegister = (role) => {
        let params = {
            username: this.state.username,
            password: this.state.password,
            email: this.state.email,
            avatar: this.state.avatar,
            role: role
        }
        console.log(params)
        apiRegister(params).then(res => {
            if (res.data.code === '402') {
                message.success('注册成功');
                this.props.handleSelectKey()
            } else if (res.data.code === '401') {
                message.error(res.data.message)
            } else if (res.data.code === '403') {
                message.error(res.data.message)
            } else {
                message.error('错误')
            }
        })
    }

    render() {
        return (
            this.state.submit ?
                <div style={{
                    height: '50vh'
                }}>
                    <motion.button
                        onClick={this.handlePhotographer}
                        style={{
                            height: '20vh',
                            width: '52vh',
                            textAlign: 'center',
                            fontSize: '5vh',
                            borderRadius: '3vh',
                            border: 'none',
                            background: 'linear-gradient(90deg, #d309e1 0%, rgb(156, 26, 255) 100%)',
                            color: 'white'
                        }}
                        whileHover={{
                            scale: 1.1
                        }}
                        whileTap={{scale: 0.9}}
                    >
                        我是摄影师
                    </motion.button>
                    <motion.button
                        onClick={this.handleModel}
                        style={{
                            marginTop: '3vh',
                            height: '20vh',
                            width: '52vh',
                            y: '1vh',
                            textAlign: 'center',
                            fontSize: '5vh',
                            borderRadius: '3vh',
                            border: 'none',
                            background: 'linear-gradient(90deg, #5cf5de 0%, #19CAAD 100%)',
                            color: 'white'
                        }}
                        whileHover={{
                            scale: 1.1
                        }}
                        whileTap={{scale: 0.9}}
                    >
                        我是模特
                    </motion.button>
                </div>
                : <Form onFinish={this.handleSubmitRegister} className="register-form">
                    <Form.Item
                        name="username"
                        rules={[{required: true, message: 'Please input your Username!'}]}
                    >
                        <Input
                            prefix={<UserOutlined id="item-icon"/>}
                            placeholder="Username"
                            size="large"
                        />
                    </Form.Item>
                    <Form.Item
                        name="avatar"
                        rules={[
                            {
                                required: true,
                                message: 'Please input the url of your avatar!',
                            },
                        ]}
                    >
                        <Input
                            prefix={<PictureOutlined id="item-icon"/>}
                            placeholder="Avatar Url"
                            size="large"
                        />
                    </Form.Item>
                    <Form.Item
                        name="email"
                        rules={[
                            {
                                type: 'email',
                                message: 'The input is not valid E-mail!',
                            },
                            {
                                required: true,
                                message: 'Please input your E-mail!',
                            },
                        ]}
                    >
                        <Input
                            prefix={<MailOutlined id="item-icon"/>}
                            placeholder="E-mail"
                            size="large"
                        />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                        ]}
                        hasFeedback
                    >
                        <Input.Password placeholder="Password"
                                        prefix={<LockOutlined id="item-icon"/>}
                                        size="large"
                        />
                    </Form.Item>
                    <Form.Item
                        name="confirm"
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'Please confirm your password!',
                            },
                            ({getFieldValue}) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                },
                            }),
                        ]}
                    >
                        <Input.Password prefix={<LockOutlined id="item-icon"/>}
                                        placeholder="Confirm Password"
                                        size="large"
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button size="large" type="primary" htmlType="submit"
                                className="register-form-button" style={{width: '100%'}}>
                            Register
                        </Button>
                    </Form.Item>
                </Form>
        )
    }
}

export default RegisterForm;