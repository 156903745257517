import React, {Component} from "react";
import {Form, Input, Button, message, InputNumber} from 'antd';
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import {Divider} from 'antd';
import './Publish.less'
import {apiPublish} from "../API/API";

class Publish extends Component {
    onFinish = values => {
        console.log('Received values of form:', values);
        let params = {
            info: values.info,
            wechat: values.wechat,
            place: values.place,
            price: values.price,
            pics: values.pics.reverse(),
        }
        apiPublish(params).then(res => {
            if (res.data.code === '402') {
                message.success('发布成功');
                window.location.reload()
            } else if (res.data.code === '401') {
                message.error(res.data.message)
            } else {
                message.error('错误')
            }
        })
    };

    render() {
        return (
            <div className={'publish-div'}>
                <Divider
                    style={{
                        color: '#19CAAD',
                        fontSize: '4vh',
                        fontWeight: 'bolder'
                    }}
                >
                    发布
                </Divider>
                <div className={'publish-form-div'}>
                    <Form name="dynamic_form_item" onFinish={this.onFinish}>
                        <Form.Item
                            name="info"
                            label="介绍"
                            rules={[{required: true, message: 'Please input info'}]}
                        >
                            <Input.TextArea
                                showCount
                                maxLength={200}
                                style={{
                                    width: '84%'
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            label="地点"
                            name="place"
                            style={{
                                width: '85%'
                            }}
                            rules={[{required: true, message: 'Please input your agreed place!'}]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="微信"
                            name="wechat"
                            style={{
                                width: '85%'
                            }}
                            rules={[{required: true, message: 'Please input your wechat!'}]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item label="价格" name="price">
                            <InputNumber style={{
                                width: '84.2%'
                            }}/>
                        </Form.Item>
                        <Form.List
                            name="pics"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input the url of your picture!',
                                },
                            ]}
                        >
                            {(fields, {add, remove}, {errors}) => (
                                <>
                                    {fields.map((field, index) => (
                                        <Form.Item
                                            label={index === 0 ? '发布封面' : '第 ' + index + ' 张图片'}
                                            required={false}
                                            key={field.key}
                                        >
                                            <Form.Item
                                                {...field}
                                                validateTrigger={['onChange', 'onBlur']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        whitespace: true,
                                                        message: "Please input the url of your picture or delete this field.",
                                                    },
                                                ]}
                                                noStyle
                                            >
                                                <Input placeholder="Picture url" style={{width: '80%'}}/>
                                            </Form.Item>
                                            &nbsp;&nbsp;&nbsp;
                                            {fields.length > 1 ? (
                                                <MinusCircleOutlined
                                                    className="dynamic-delete-button"
                                                    onClick={() => remove(field.name)}
                                                />
                                            ) : null}
                                        </Form.Item>
                                    ))}
                                    <Form.Item>
                                        <Button
                                            type="dashed"
                                            onClick={() => add()}
                                            style={{width: '85%'}}
                                            icon={<PlusOutlined/>}
                                        >
                                            添加图片
                                        </Button>
                                        <Button
                                            type="dashed"
                                            onClick={() => {
                                                add('', 0);
                                            }}
                                            style={{width: '85%', marginTop: '20px'}}
                                            icon={<PlusOutlined/>}
                                        >
                                            添加图片到头部
                                        </Button>
                                        <Form.ErrorList errors={errors}/>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                style={{
                                    width: '85%',
                                    marginTop: '10px',
                                    height: '10vh',
                                    fontSize: '3vh',
                                    marginBottom: '5vh'
                                }}
                            >
                                发布
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        );
    }
}

export default Publish;