import React, {Component} from "react";
import {HashRouter, Route, Switch} from "react-router-dom";
import DefaultLayout from "../Layout/DefaultLayout";

class PageRouter extends Component {
    render() {
        return (
            <div id={'router'}>
                <HashRouter>
                    <Switch>
                        <Route path={'/'} component={DefaultLayout}/>
                    </Switch>
                </HashRouter>
            </div>
        )
    }
}

export default PageRouter;