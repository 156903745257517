import React, {Component} from "react";
import {motion} from "framer-motion"
import './Framer.less'
import ContentRouter from "../Router/ContentRouter";

class Framer extends Component {
    render() {
        const variants = {
            collapsed: {
                x: '15vh', width: '163vh'
            },
            not_collapsed: {
                x: '32vh', width: '147vh'
            }
        }
        return (
            <motion.div
                initial={'collapsed'}
                animate={this.props.collapsed ? 'collapsed' : 'not_collapsed'}
                variants={variants}
                className={'framer-content'}
            >
                <div className={'framer-content-div'}>
                    <ContentRouter/>
                </div>
                <div className={'framer-footer'}>
                    北拍 ©2021 Created by BJShot
                </div>
            </motion.div>
        )
    }
}

export default Framer;