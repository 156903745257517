import React, {Component} from "react";
import {Button, Form, Input, Steps, Row, Col, message} from "antd";
import '../UserForm.less'
import {LockOutlined, MailOutlined, SecurityScanOutlined, UserOutlined} from "@ant-design/icons";
import {apiCheck, apiConfirm, apiReset} from "../../../API/API";

const {Step} = Steps;

class ForgetPasswordForm extends Component {
    state = {
        forgetStatus: 'check',
        currentStep: 0,
        stepProcessStatus: 'process',
        code: '',
        loading: false,
    }

    handleSubmitCheck = values => {
        this.setState({
            loading: true
        })
        let params = {
            ...values
        }
        console.log('Received values of form: ', params);
        apiCheck(params).then(res => {
            this.setState({
                loading: false
            })
            if (res.data.code === '402') {
                message.success(res.data.message);
                this.handleCheckSuccessfully()
            } else if (res.data.code === '401') {
                message.error(res.data.message)
            } else if (res.data.code === '403') {
                message.error(res.data.message)
            } else {
                message.error('Error')
            }
        })
    }

    handleSubmitConfirm = values => {
        this.setState({
            loading: true
        })
        let params = {
            ...values
        }
        console.log('Received values of form: ', params);
        apiConfirm(params).then(res => {
            this.setState({
                loading: false
            })
            if (res.data.code === '402') {
                message.success(res.data.message);
                this.handleConfirmSuccessfully()
                this.setState({
                    code: values.code,
                })
            } else if (res.data.code === '401') {
                message.error(res.data.message)
            } else if (res.data.code === '403') {
                message.error(res.data.message)
            } else {
                message.error('Error')
            }
        })
    }

    handleSubmitReset = values => {
        this.setState({
            loading: true
        })
        let params = {
            password: values.password,
            code: this.state.code,
        }
        apiReset(params).then(res => {
            this.setState({
                loading: false
            })
            if (res.data.code === '402') {
                message.success(res.data.message);
                window.location.href = "";
            } else if (res.data.code === '401') {
                message.error(res.data.message)
            } else if (res.data.code === '403') {
                message.error(res.data.message)
            } else {
                message.error('Error')
            }
        })
    }

    handleCheckSuccessfully = () => {
        this.setState({
            forgetStatus: 'confirm',
            currentStep: 1,
        })
    }

    handleConfirmSuccessfully = () => {
        this.setState({
            forgetStatus: 'reset',
            currentStep: 2,
        })
    }

    render() {
        let {forgetStatus, currentStep, stepProcessStatus} = this.state;

        return (
            <Row>
                <Col span={8}>
                    <Steps direction="vertical" size={'small'} current={currentStep} status={stepProcessStatus}>
                        <Step title="Check Identity"/>
                        <Step title="Confirm Code"/>
                        <Step title="Reset Password"/>
                    </Steps>
                </Col>
                <Col span={16}>
                    {
                        forgetStatus === 'check' ?
                            <Form onFinish={this.handleSubmitCheck} className="forget-password-form"
                                  initialValues={{remember: true}}>
                                <Form.Item
                                    name="username"
                                    rules={[{required: true, message: 'Please input your Username!'}]}
                                >
                                    <Input
                                        prefix={<UserOutlined id="item-icon"/>}
                                        placeholder="Username"
                                        size="large"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="email"
                                    rules={[
                                        {
                                            type: 'email',
                                            message: 'The input is not valid E-mail!',
                                        },
                                        {
                                            required: true,
                                            message: 'Please input your E-mail!',
                                        },
                                    ]}
                                >
                                    <Input
                                        prefix={<MailOutlined id="item-icon"/>}
                                        placeholder="E-mail"
                                        size="large"
                                    />
                                </Form.Item>
                                <Form.Item style={{marginBottom: '10px'}}>
                                    <Button size="large" type="primary" htmlType="submit" className="login-form-button"
                                            style={{width: '100%'}} loading={this.state.loading} disabled={'true'}>
                                        RESET YOUR PASSWORD
                                    </Button>
                                </Form.Item>
                                <Form.Item style={{marginBottom: '0'}}>
                                    <Button type="link" block style={{textAlign: 'right'}} onClick={() => {
                                        this.props.handleLoginStatus('login')
                                    }}>
                                        LOGIN
                                    </Button>
                                </Form.Item>
                            </Form> : forgetStatus === 'confirm' ?
                                <Form onFinish={this.handleSubmitConfirm} className="forget-password-form"
                                      initialValues={{remember: true}}>
                                    <Form.Item
                                        name="code"
                                        rules={[{required: true, message: 'Please input your Verification Code!'}]}
                                    >
                                        <Input
                                            prefix={<SecurityScanOutlined id="item-icon"/>}
                                            placeholder="Verification Code"
                                            size="large"
                                        />
                                    </Form.Item>
                                    <Form.Item style={{marginBottom: '10px'}}>
                                        <Button size="large" type="primary" htmlType="submit" className="login-form-button"
                                                style={{width: '100%'}}>
                                            CONFIRM
                                        </Button>
                                    </Form.Item>
                                </Form> :
                                <Form onFinish={this.handleSubmitReset} className="forget-password-form"
                                      initialValues={{remember: true}}>
                                    <Form.Item
                                        name="password"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your password!',
                                            },
                                        ]}
                                        hasFeedback
                                    >
                                        <Input.Password placeholder="Password"
                                                        prefix={<LockOutlined id="item-icon"/>}
                                                        size="large"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="confirm"
                                        dependencies={['password']}
                                        hasFeedback
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please confirm your password!',
                                            },
                                            ({getFieldValue}) => ({
                                                validator(_, value) {
                                                    if (!value || getFieldValue('password') === value) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input.Password prefix={<LockOutlined id="item-icon"/>}
                                                        placeholder="Confirm Password"
                                                        size="large"
                                        />
                                    </Form.Item>
                                    <Form.Item style={{marginBottom: '10px'}}>
                                        <Button size="large" type="primary" htmlType="submit" className="login-form-button"
                                                style={{width: '100%'}}>
                                            RESET PASSWORD
                                        </Button>
                                    </Form.Item>
                                </Form>
                    }
                </Col>
            </Row>
        )
    }
}

export default ForgetPasswordForm;