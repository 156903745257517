import React, {Component} from "react";
import {HashRouter, Route, Switch} from "react-router-dom";
import Detail from "../Components/Detail";

class DetailRouter extends Component {
    render() {
        return (
            <div id={'router'}>
                <HashRouter>
                    <Switch>
                        <Route path={'/list/:id'} component={Detail}/>
                    </Switch>
                </HashRouter>
            </div>
        )
    }
}

export default DetailRouter;