import React, {Component} from "react";
import {motion} from "framer-motion"
import './Card.less'

class Card extends Component {
    state = {
        height: '',
        width: ''
    }

    componentDidMount() {
        this.setState({
            height: document.getElementById('card-img-' + this.props.info.id).clientHeight,
            width: document.getElementById('card-img-' + this.props.info.id).clientWidth
        })
    }

    onHoverImg = () => {
        this.setState({
            height: document.getElementById('card-img-' + this.props.info.id).clientHeight,
            width: document.getElementById('card-img-' + this.props.info.id).clientWidth
        })
    }

    onClickHover = () => {
        window.location.href = '/#/list/' + this.props.info.id
    }

    render() {
        const variants = {
            hover: {
                backgroundColor: 'rgba(0, 0, 0, 0.75)',
                color: 'rgba(255, 255, 255, 1)'
            },
            not_hover: {
                backgroundColor: 'rgba(0, 0, 0, 0)',
                color: 'rgba(255, 255, 255, 0)'
            }
        }
        return (
            <motion.div
                className={'card-div'}
            >
                <motion.div
                    variants={variants}
                    initial={'not_hover'}
                    className={'card-hover'}
                    whileHover={'hover'}
                    style={{
                        width: this.state.width,
                        height: this.state.height,
                        paddingTop: this.state.height / (2.7)
                    }}
                    onClick={this.onClickHover}
                >
                    {
                        this.props.info.username
                    }
                </motion.div>
                <div className={'card-time'}>
                    <p
                        style={{
                            lineHeight: '3vh',
                        }}
                    >
                        {
                            this.props.info.date.substr(5, 2) + '月'
                        }
                        <br/>
                        {
                            this.props.info.date.substr(8, 2) + '日'
                        }
                    </p>

                </div>
                <img
                    id={'card-img-' + this.props.info.id}
                    style={{
                        width: '90%',
                    }}
                    onMouseOver={this.onHoverImg}
                    src={this.props.info.cover}
                    alt={this.props.info.username + '的发布'}
                />
            </motion.div>
        )
    }
}

export default Card;