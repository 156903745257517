import Qs from 'qs'
import fetch from "./Axios";

export function apiLogin(params) {
    return fetch({
        url: '/account/login/',
        method: 'post',
        data: Qs.stringify(params),
    })
}

export function apiRegister(params) {
    return fetch({
        url: '/account/register/',
        method: 'post',
        data: Qs.stringify(params),
    })
}

export function apiLogout() {
    return fetch({
        url: '/account/logout/',
        method: 'get',
    })
}

export function apiCheck(params) {
    return fetch({
        url: '/account/check/',
        method: 'post',
        data: Qs.stringify(params),
    })
}

export function apiConfirm(params) {
    return fetch({
        url: '/account/confirm/',
        method: 'post',
        data: Qs.stringify(params),
    })
}

export function apiReset(params) {
    return fetch({
        url: '/account/reset/',
        method: 'post',
        data: Qs.stringify(params),
    })
}

export function apiIsLogged() {
    return fetch({
        url: '/account/islogged/',
        method: 'get',
    })
}

export function apiPublish(params) {
    return fetch({
        url: '/trade/publish/',
        method: 'post',
        data: Qs.stringify(params),
    })
}

export function apiGetList(params) {
    return fetch({
        url: '/trade/list/',
        method: 'post',
        data: Qs.stringify(params),
    })
}

export function apiGetDetail(params) {
    return fetch({
        url: '/trade/detail/',
        method: 'post',
        data: Qs.stringify(params),
    })
}

export function apiFinishOrder(params) {
    return fetch({
        url: '/trade/finish/',
        method: 'post',
        data: Qs.stringify(params),
    })
}