import React from 'react';
import './App.less';
import PageRouter from "./Router/PageRouter";
import DetailRouter from "./Router/DetailRouter";


const App = () => (
    <div className="App">
        <DetailRouter/>
        <PageRouter/>
    </div>
);

export default App;