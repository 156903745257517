import React, {Component} from "react";
import {HashRouter, Route, Switch} from "react-router-dom";
import AccountContent from "../Components/AccountContent";
import CardList from "../Components/CardList";
import Publish from "../Components/Publish";
import Order from "../Components/Order";

class PageRouter extends Component {
    render() {
        return (
            <div id={'router'}>
                <HashRouter>
                    <Switch>
                        <Route path={'/account'} component={AccountContent}/>
                        <Route path={'/list'} component={CardList}/>
                        <Route path={'/order/:id'} component={Order}/>
                        <Route path={'/publish'} component={Publish}/>
                    </Switch>
                </HashRouter>
            </div>
        )
    }
}

export default PageRouter;