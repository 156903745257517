import React, {Component} from "react";
import Card from './Card'
import './CardList.less'
import {apiGetList} from "../API/API";
import {message} from "antd";

class CardList extends Component {
    state = {
        left_infos: [],
        right_infos: []
    }

    componentWillMount() {
        let params = {}
        apiGetList(params).then(res => {
            if (res.data.code === '402') {
                let left_height
                let right_height
                // for (let i = 0; i < res.data.trades.length; i += 2) {
                //     left_infos.unshift(res.data.trades[i])
                // }
                // for (let i = 1; i < res.data.trades.length; i += 2) {
                //     right_infos.unshift(res.data.trades[i])
                // }
                // this.setState({
                //     left_infos: left_infos,
                //     right_infos: right_infos
                // })
                for (let i = 0; i < res.data.trades.length; i++) {
                    left_height = document.getElementById('card-list-left').clientHeight
                    right_height = document.getElementById('card-list-right').clientHeight
                    if (right_height < left_height) {
                        this.setState({
                            right_infos: [res.data.trades[i], ...this.state.right_infos]
                        })
                    } else {
                        this.setState({
                            left_infos: [res.data.trades[i], ...this.state.left_infos]
                        })
                    }
                }
            } else if (res.data.code === '401') {
                message.error(res.data.message)
            } else {
                message.error('错误')
            }
        })
    }

    renderCards(infos) {
        return infos.map((ele, index) => {
            return (
                <Card info={ele}/>
            )
        })
    }

    render() {
        return (
            <div className={'card-list-div'}>
                <div className={'card-list-left'} id={'card-list-left'}>
                    {
                        this.renderCards(this.state.left_infos)
                    }
                </div>
                <div className={'card-list-right'} id={'card-list-right'}>
                    {
                        this.renderCards(this.state.right_infos)
                    }
                </div>
            </div>
        )
    }
}

export default CardList;