import React, {Component} from "react";
import {Layout, Menu, message} from 'antd';
import {motion} from "framer-motion"
import './DefaultLayout.less'
import {
    CameraOutlined, UserOutlined, CommentOutlined, UploadOutlined, InfoCircleOutlined,
} from '@ant-design/icons';
import Framer from '../Components/Framer'
import {Link} from "react-router-dom";
import {apiIsLogged} from "../API/API";

const {Sider} = Layout;

class DefaultLayout extends Component {
    state = {
        collapsed: false,
        avatar: 'https://img0.baidu.com/it/u=1051750546,2567387195&fm=26&fmt=auto',
        isLogged: false,
    };

    onCollapse = collapsed => {
        console.log(collapsed);
        this.setState({collapsed});
    };

    componentWillMount() {
        apiIsLogged().then(res => {
            if (res.data.code === '402') {
                this.setState({
                    avatar: res.data.avatar,
                    isLogged: true,
                })
            } else if (res.data.code === '401') {
                // message.error(res.data.message)
            } else {
                message.error('错误')
            }
        })
    }

    render() {
        const variants = {
            not_collapsed: {
                height: '16vh',
                width: '16vh',
                borderRadius: '10vh',
                border: '0.5px solid #19CAAD',
                x: '6vh',
                marginTop: '5vh',
                marginBottom: '5vh'
            },
            collapsed: {
                height: '8vh',
                width: '8vh',
                borderRadius: '2vh',
                border: '0.3px solid #19CAAD',
                x: '2vh',
                marginTop: '5vh',
                marginBottom: '5vh'
            }
        }
        return (
            <Layout style={{minHeight: '100vh'}}>
                <Sider
                    collapsible
                    collapsed={this.state.collapsed}
                    onCollapse={this.onCollapse}
                    theme={'light'}
                    style={{
                        overflow: 'auto',
                        height: '100vh',
                        position: 'fixed',
                        left: 0,
                        zIndex: 1000,
                    }}
                >
                    <motion.img
                        src={this.state.avatar}
                        variants={variants}
                        animate={this.state.collapsed ? 'collapsed' : 'not_collapsed'}
                        initial={'not_collapsed'}
                    />
                    <Menu defaultSelectedKeys={['1']} mode="inline">
                        <Menu.Item key="1" icon={<CameraOutlined/>}>
                            <Link to={'/list'} style={{color: this.state.collapsed ? 'white' : 'black'}}>
                                约拍
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="2" icon={<CommentOutlined/>} disabled={'true'}>
                            社区
                        </Menu.Item>
                        {this.state.isLogged ?
                            <Menu.Item key="4" icon={<UploadOutlined/>}>
                                <Link to={'/publish'} style={{color: this.state.collapsed ? 'white' : 'black'}}>
                                    发布
                                </Link>
                            </Menu.Item> : null
                        }
                        {this.state.isLogged ?
                            <Menu.Item key="5" icon={<InfoCircleOutlined/>} disabled={'true'}>
                                <Link to={'/account'} style={{color: this.state.collapsed ? 'white' : 'black'}}>
                                    我的
                                </Link>
                            </Menu.Item> : null
                        }
                        <Menu.Item key="3" icon={<UserOutlined/>}>
                            <Link to={'/account'} style={{color: this.state.collapsed ? 'white' : 'black'}}>
                                账户
                            </Link>
                        </Menu.Item>
                    </Menu>
                </Sider>
                <Framer collapsed={this.state.collapsed}/>
            </Layout>
        )
    }
}

export default DefaultLayout;