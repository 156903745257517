import React, {Component} from "react";
import {Button, Form, Input, message} from "antd";
import {LockOutlined, UserOutlined} from "@ant-design/icons";
import '../UserForm.less'
import {apiLogin} from "../../../API/API";

class LoginForm extends Component {
    handleSubmitLogin = values => {
        console.log('Received values of form: ', values);
        let params = {
            ...values
        }
        apiLogin(params).then(res => {
            if (res.data.code === '402') {
                message.success('登陆成功');
                this.props.handleLogged()
                window.location.href='/#/list'
                window.location.reload();
            } else if (res.data.code === '401') {
                message.error(res.data.message)
            } else if (res.data.code === '403') {
                message.error(res.data.message)
            } else {
                message.error('错误')
            }
        })
    };

    render() {
        return (
            <Form onFinish={this.handleSubmitLogin} className="login-form" initialValues={{remember: true}}>
                <Form.Item
                    name="username"
                    rules={[{required: true, message: 'Please input your Username or E-mail!'}]}
                >
                    <Input
                        prefix={<UserOutlined id="item-icon"/>}
                        placeholder="Username or E-mail"
                        size="large"
                    />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[{required: true, message: 'Please input your Password!'}]}
                >
                    <Input
                        prefix={<LockOutlined id="item-icon"/>}
                        type="password"
                        placeholder="Password"
                        size="large"
                    />
                </Form.Item>
                <Form.Item style={{marginBottom: '10px'}}>
                    <Button size="large" type="primary" htmlType="submit" className="login-form-button"
                            style={{width: '100%'}}>
                        Login
                    </Button>
                </Form.Item>
                <Form.Item style={{marginBottom: '0'}}>
                    <Button type="link" block style={{textAlign: 'right'}} onClick={() => {
                        this.props.handleLoginStatus('forget')
                    }}>
                        Forget your password？
                    </Button>
                </Form.Item>
            </Form>
        )
    }
}

export default LoginForm;