import React, {Component} from "react";
import {Descriptions, message} from 'antd';
import {Divider} from 'antd';
import {apiFinishOrder, apiGetDetail, apiIsLogged} from "../API/API";
import './Order.less'
import {motion} from "framer-motion";

class Order extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
            isLogged: false,
            detail: {
                'pics': [],
                'is_finished': false
            },
        }
    }

    componentWillMount() {
        apiIsLogged().then(res => {
            if (res.data.code === '402') {
                this.setState({
                    isLogged: true,
                })
            } else if (res.data.code === '401') {
                // message.error(res.data.message)
            } else {
                message.error('错误')
            }
        })
        let params = {
            id: this.state.id
        }
        apiGetDetail(params).then(res => {
            if (res.data.code === '402') {
                this.setState({
                    detail: res.data.detail,
                })
            } else if (res.data.code === '401') {
                message.error(res.data.message)
            } else {
                message.error('错误')
            }
        })
    }

    finishOrder = () => {
        let params = {
            id: this.state.id
        }
        apiFinishOrder(params).then(res => {
            if (res.data.code === '402') {
                window.location.href = '/#/list'
            } else if (res.data.code === '401') {
                message.error(res.data.message)
            } else {
                message.error('错误')
            }
        })
    }

    render() {
        return (
            <div className={'order-div'}>
                <Divider
                    style={{
                        color: '#19CAAD',
                        fontSize: '4vh',
                        fontWeight: 'bolder'
                    }}
                >
                    订单
                </Divider>
                <div className={'order-content'}>
                    <Descriptions title="详细信息">
                        <Descriptions.Item label="发布者">
                            {
                                this.state.detail.post_user
                            }
                        </Descriptions.Item>
                        <Descriptions.Item label="发布日期">
                            {
                                this.state.detail.date
                            }
                        </Descriptions.Item>
                        <Descriptions.Item label="价格">
                            {
                                this.state.detail.price
                            }
                        </Descriptions.Item>
                        <Descriptions.Item label="微信">
                            {
                                this.state.detail.wechat
                            }
                        </Descriptions.Item>
                        <Descriptions.Item label="地点">
                            {
                                this.state.detail.place
                            }
                        </Descriptions.Item>
                    </Descriptions>
                    <div
                        style={{
                            float:'right',
                        }}
                    >
                        <img
                            width={'200vh'}
                            src={'https://shellmia0-blog-1304113800.cos.ap-beijing.myqcloud.com/QQ%E5%9B%BE%E7%89%8720211120182645.jpg'}
                            alt={'支付二维码'}
                        />
                    </div>
                    <div
                        style={{
                            float:'right',
                            marginRight:'10vh'
                        }}
                    >
                        <img
                            width={'200vh'}
                            src={'https://shellmia0-blog-1304113800.cos.ap-beijing.myqcloud.com/QQ%E5%9B%BE%E7%89%8720211120182635.jpg'}
                            alt={'支付二维码'}
                        />
                    </div>
                    <motion.button
                        onClick={this.finishOrder}
                        style={{
                            height: '10vh',
                            width: '100%',
                            textAlign: 'center',
                            fontSize: '3vh',
                            marginTop: '4vh',
                            borderRadius: '3vh',
                            border: 'none',
                            background: 'linear-gradient(90deg, #d309e1 0%, rgb(156, 26, 255) 100%)',
                            color: 'white'
                        }}
                        whileHover={{
                            scale: 1.1
                        }}
                        whileTap={{scale: 0.9}}
                    >
                        确定
                    </motion.button>
                </div>
            </div>
        );
    }
}

export default Order;