import React, {Component} from "react";
import {Col, message, Tabs} from "antd";
import './MainUserForm.less'
import {apiIsLogged} from "../../API/API";
import LoginForm from "./LoginForm/LoginForm";
import ForgetPasswordForm from "./ForgetPasswordForm/ForgetPasswordForm";
import LogoutForm from "./LogoutForm/LogoutForm";
import RegisterForm from "./RegisterForm/RegisterForm";

const {TabPane} = Tabs;

class UserMainForm extends Component {
    state = {
        loginStatus: "login",
        tagWidth: 10,
        tagOffset: 2,
        selectedKey: '1',
    };

    componentWillMount() {
        apiIsLogged().then(res => {
            if (res.data.code === '402') {
                this.setState({
                    loginStatus: 'logged',
                })
            } else if (res.data.code === '401') {
                // message.error(res.data.message)
            } else {
                message.error('错误')
            }
        })
    }

    handleLogged = () => {
        this.setState({
            loginStatus: this.state.loginStatus === 'login' ? 'logged' : 'login',
        })
    }

    handleSelectKey = () => {
        this.setState({
            selectedKey: this.state.selectedKey === '1' ? '2' : '1',
        })
        this.handleLoginStatus('login')
    }

    handleLoginStatus = LoginStatus => {
        this.setState(
            {
                loginStatus: LoginStatus,
            }
        )
        if (LoginStatus === 'forget') {
            this.setState(
                {
                    tagWidth: 12,
                    tagOffset: 1,
                }
            )
        } else {
            this.setState(
                {
                    tagWidth: 10,
                    tagOffset: 2,
                }
            )
        }
    }

    render() {
        let {loginStatus, tagWidth, tagOffset} = this.state;
        const renderTabBar = (props, DefaultTabBar) => (

            <DefaultTabBar {...props} style={{width: '100%'}}/>

        );
        return (
            <Col span={tagWidth} offset={tagOffset} className={'UserCard'}>
                <Tabs
                    onChange={this.handleSelectKey}
                    size={'large'}
                    animated={true}
                    centered={true}
                    tabBarGutter={50}
                    renderTabBar={renderTabBar}
                    activeKey={this.state.selectedKey}
                >
                    <TabPane
                        tab={loginStatus === 'forget' ? "RESET PASSWORD" : loginStatus === 'login' ? "LOGIN" : 'LOGOUT'}
                        key="1"
                        style={{padding: "20px 30px 10px"}}>
                        {
                            loginStatus === 'login' ?
                                <LoginForm handleLoginStatus={this.handleLoginStatus.bind(this)}
                                           handleLogged={this.handleLogged.bind(this)}/>
                                : loginStatus === 'forget' ?
                                    <ForgetPasswordForm handleLoginStatus={this.handleLoginStatus.bind(this)}/>
                                    : <LogoutForm handleLogged={this.handleLogged.bind(this)}/>
                        }
                    </TabPane>
                    {
                        loginStatus === 'logged' ? null :
                            <TabPane tab="REGISTER" key="2" style={{padding: "20px 30px 0"}}>
                                <RegisterForm handleSelectKey={this.handleSelectKey.bind(this)}/>
                            </TabPane>
                    }
                </Tabs>
            </Col>
        );
    }
}

export default UserMainForm;