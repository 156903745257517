import React, {Component} from "react";
import MainUserForm from "./UserForm/MainUserForm";
import AccountContentPics from "./AccountContentPics";
import './AccountContent.less'

class AccountContent extends Component {
    render() {
        return (
            <div className={'account-content-div'}>
                <MainUserForm/>
                <AccountContentPics/>
            </div>
        )
    }
}

export default AccountContent;