import React, {Component} from "react";
import './Detail.less'
import {apiGetDetail, apiIsLogged} from "../API/API";
import {Image, message} from "antd";
import {motion} from "framer-motion";
import {CloseSquareOutlined} from "@ant-design/icons";

class Detail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
            isLogged: false,
            detail: {
                'pics': [],
                'is_finished': false
            },
        }
    }

    componentWillMount() {
        apiIsLogged().then(res => {
            if (res.data.code === '402') {
                this.setState({
                    isLogged: true,
                })
            } else if (res.data.code === '401') {
                // message.error(res.data.message)
            } else {
                message.error('错误')
            }
        })
        let params = {
            id: this.state.id
        }
        apiGetDetail(params).then(res => {
            if (res.data.code === '402') {
                this.setState({
                    detail: res.data.detail,
                })
                console.log(res.data.detail)
            } else if (res.data.code === '401') {
                message.error(res.data.message)
            } else {
                message.error('错误')
            }
        })
    }

    onClick = () => {
        window.location.href = '/#/list'
    }

    handleClickButton = () => {
        if (!this.state.isLogged) {
            window.location.href = '/#/account'
        } else {
            if (this.state.detail.is_finished) {
                return null
            } else {
                window.location.href = '/#/order/' + this.state.id
            }
        }
    }

    renderPics(pics) {
        return pics.map((ele, index) => {
            return (
                <motion.div
                    className={'detail-pic'}
                    whileHover={{
                        scale: 1.1
                    }}
                    whileTap={{scale: 0.9}}
                    initial={{
                        x: '-200vh'
                    }}
                    animate={{
                        x: index === 0 ? '5vh' : '-200vh'
                    }}
                    style={{
                        y: '5vh'
                    }}
                >
                    <Image
                        id={'pic' + index}
                        style={{
                            maxWidth: '90vh',
                            maxHeight: '82vh',
                        }}
                        src={ele}
                    />
                </motion.div>
            )
        })
    }

    render() {
        return (
            <motion.div
                className={'detail-div'}
                // onClick={this.onClickDiv}
            >
                <motion.div
                    className={'detail-pics'}
                    id={'detail-pics'}
                >
                    <Image.PreviewGroup>
                        {
                            this.renderPics(this.state.detail.pics)
                        }
                    </Image.PreviewGroup>
                </motion.div>
                <div className={'detail-info'}>
                    <motion.button
                        onClick={this.handleClickButton}
                        style={{
                            height: '8vh',
                            width: '50vh',
                            textAlign: 'center',
                            fontSize: '5vh',
                            borderRadius: '3vh',
                            border: 'none',
                            background: 'linear-gradient(90deg, #19CAAD 0%, #F4606C 100%)',
                            color: 'white'
                        }}
                        whileHover={{
                            scale: 1.1
                        }}
                        whileTap={{scale: 0.9}}
                    >
                        {
                            !this.state.isLogged ? '请登录' : this.state.detail.is_finished ? '已约拍' : '约拍'
                        }
                    </motion.button>
                </div>
                <motion.div
                    className={'detail-cancel'}
                    whileHover={{
                        scale: 1.1
                    }}
                    whileTap={{scale: 0.9}}
                    onClick={this.onClick}
                >
                    <CloseSquareOutlined/>
                </motion.div>
                <div className={'detail-text'}>
                    <div
                        className={'detail-text-title'}
                    >
                        发布
                    </div>
                    <div
                        className={'detail-text-content'}
                    >
                        {
                            this.state.detail.plaintext
                        }
                    </div>
                </div>
            </motion.div>
        );
    }
}

export default Detail;