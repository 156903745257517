import React, {Component} from "react";
import {motion} from "framer-motion";
import './AccountContentPics.less'

class AccountContentPics extends Component {
    render() {
        return (
            <motion.div
                className={'account-pic'}
            >
                <motion.div
                    whileHover={{
                        zIndex: 999,
                        scale: 1.1
                    }}
                    whileTap={{scale: 0.9}}
                    className={'account-card-1'}
                    drag
                    dragConstraints={{
                        top: -20,
                        left: -20,
                        right: 20,
                        bottom: 20,
                    }}
                >
                    <img
                        style={{
                            width: '45vh',
                            marginTop: '4vh',
                            marginLeft: '2vh'
                        }}
                        src={"https://shellmia0-blog-1304113800.cos.ap-beijing.myqcloud.com/Cache_3f6046565ae74f8b..jpg"}
                        alt={'pic'}
                    />
                </motion.div>
                <motion.div
                    whileHover={{
                        zIndex: 999,
                        scale: 1.1
                    }}
                    whileTap={{scale: 0.9}}
                    className={'account-card-2'}
                    drag
                    dragConstraints={{
                        top: -20,
                        left: -20,
                        right: 20,
                        bottom: 20,
                    }}
                >
                    <img
                        style={{
                            width: '45vh',
                            marginTop: '4vh',
                            marginLeft: '2vh'
                        }}
                        src={"https://shellmia0-blog-1304113800.cos.ap-beijing.myqcloud.com/pexels-diva-plavalaguna-6150080.jpg"}
                        alt={'pic'}
                    />
                </motion.div>
                <motion.div
                    whileHover={{
                        zIndex: 999,
                        scale: 1.1
                    }}
                    whileTap={{scale: 0.9}}
                    className={'account-card-3'}
                    drag
                    dragConstraints={{
                        top: -20,
                        left: -20,
                        right: 20,
                        bottom: 20,
                    }}
                >
                    <img
                        style={{
                            width: '45vh',
                            marginTop: '4vh',
                            marginLeft: '2vh'
                        }}
                        src={"https://shellmia0-blog-1304113800.cos.ap-beijing.myqcloud.com/pexels-tatiana-twinslol-5444995.jpg"}
                        alt={'pic'}
                    />
                </motion.div>
            </motion.div>
        )
    }
}

export default AccountContentPics;