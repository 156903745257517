import React, {Component} from "react";
import {Button, Form, message} from "antd";
import {apiLogout} from "../../../API/API";
import {CheckCircleTwoTone, LogoutOutlined} from "@ant-design/icons";
import '../UserForm.less'

class LogoutForm extends Component {
    handleSubmitLogout = () => {
        apiLogout().then(res => {
            if (res.data.code === '402') {
                message.success('登出成功');
                this.props.handleLogged()
                window.location.href = '/#/list'
                window.location.reload();
            } else {
                message.error('错误')
            }
        })
    };

    render() {
        return (
            <div className={'logged-div'}>
                <CheckCircleTwoTone className={'logged-logo'} twoToneColor="#52c41a"/>
                <Form onFinish={this.handleSubmitLogout} className="login-form" initialValues={{remember: true}}>
                    <Form.Item style={{marginBottom: '10px'}}>
                        <Button size="large" type="primary" htmlType="submit" className="login-form-button"
                                style={{width: '100%'}} icon={<LogoutOutlined/>}>
                            Logout
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        )
    }
}

export default LogoutForm;